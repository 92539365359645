<script setup lang="ts">
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';

// INFO: maps here the data from block
const props = defineProps<{
  content: {
    data: {
      streamContent: {
        instagramId: string;
        link: string;
        media: {
          url: string;
          alt: string;
          title: string;
          thumbnails: {
            height: number;
            id: string | number;
            url: string | null;
            width: number;
          }[];
        };
        videoPreviewMedia?: {
          url?: string;
          thumbnails: {
            height: number;
            id: string | number;
            url: string | null;
            width: number;
          }[];
        };
      }[];
    };
    translated: {
      config: {
        buttonLink: {
          value: string;
        };
        buttonText: {
          value: string;
        };
        title: {
          value: string;
        };
      };
    };
  };
}>();

// TODO: thumbnails
type InstagramFeedData = {
  instagramId: string;
  link: string;
  media: {
    url: string;
    alt: string;
    title: string;
    thumbnails: string;
  };
  videoPreviewMedia?: {
    url?: string;
    thumbnails: string;
  };
};

const feedData: InstagramFeedData[] = [];

props.content?.data?.streamContent?.forEach((item) => {
  feedData.push({
    instagramId: item.instagramId,
    link: item.link,
    media: {
      url: item.media.url,
      alt: item.media.alt,
      title: item.media.title,
      thumbnails: item.media ? getSrcSetForMedia(item.media as any) : '',
    },
    videoPreviewMedia: {
      url: item.videoPreviewMedia?.url,
      thumbnails: item.videoPreviewMedia
        ? getSrcSetForMedia(item.videoPreviewMedia as any)
        : '',
    },
  });
});

const feedInfo = {
  buttonLink: props.content.translated.config.buttonLink.value,
  buttonText: props.content.translated.config.buttonText.value,
  title: props.content.translated.config.title.value,
};

// TODO: use thumbnails  LSWS-818
</script>

<template>
  <div>
    <Instagram
      :feed-data="feedData"
      :button-link="feedInfo.buttonLink"
      :button-text="feedInfo.buttonText"
      :title="feedInfo.title"
    />
  </div>
</template>
